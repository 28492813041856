import Vue from 'vue'
// 自定义指令防止按钮重复点击
const dbclick = Vue.directive('dbclick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1500)
        // v-dbclick='1500'
      }
    })
  }
})
export { dbclick }
