// 数据统计

export default {
    path: "/statistics",
    name: "Statistics",
    component: () =>
        import(
            /* webpackChunkName: "statistics" */
            "../views/statistics/statistics.vue"
        ),
    title: "业绩统计",
    icon: "statistics",

    children: [
        {
            path: "orderstatistics",
            name: "Orderstatistics",
            component: () =>
                import(
                    /* webpackChunkName: "statistics" */
                    "../views/statistics/orderData/orderstatistics.vue"
                ),
            title: "成交统计",
        },
        {
            path: "salesData",
            name: "SalesData",
            component: () =>
                import(
                    "../views/statistics/salesData/salesData.vue"
                ),
            title: "销售数据",
        }
    ]
};