/**
 * 这个文件要全局混入的代码：
 * 1、所有页面都要执行的 更新 store 中当前路由信息 的函数，
 *    这个数据变动后会跟新视图层的面包屑
 * 2、大多数页面都要用到的 store 状态
 * 
 * 文件将在入口文件 main.js 中被使用
 */

import Vue from "vue";
import { mapState } from "vuex"

Vue.mixin({

    // 面包屑更新程序
    // mounted: function () {
    //     const route = this.$route;
    //     if (route && route.meta && route.meta.title) {
    //         this.$store.commit("setBreadList", route.matched.map(item => ({
    //             title: item.meta.title
    //         })).filter(item => item.title != "首页")) // 排除首页
    //     }
    // },

    // 全局状态
    computed: {
        ...mapState([ "userInfo", ])
    }
});

/**
 *  this.$store.commit("setBreadList", route.matched.map(item => {
                const found = this.userInfo.pathList.find(it => it.path === item.path);
                const title = found?.menuName || item.meta.title;
                return { title }
            }).filter(item => item.title != "首页")) // 排除首页
 */