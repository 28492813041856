// 订单管理

export default {
    path: "/core",
    name: "Core",
    component: () =>
        import(
            /* webpackChunkName: "core" */
            "../views/_2_core/core.vue"
        ),
    title: "核心模块",
    icon: "user",

    children: [
        {
            path: "staff",
            name: "StaffList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/staffList/staffList.vue"
                ),
            title: "员工列表",
        },
        {
            path: "channel",
            name: "ChannelList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/channelList/channelList.vue"
                ),
            title: "渠道商",
        },

        {
            path: "product",
            name: "ProductList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/productList/productList.vue"
                ),
            title: "产品列表",
        },
        {
            path: "rateAllocation",
            name: "RateAllocationList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    '../views/_2_core/rateAllocationList/rateAllocationList.vue'
                ),
            title: "佣金岗位比例",
        },
        {
            path: "bonusRatio",
            name: "BonusRatioList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    '../views/_2_core/bonusRatioList/bonusRatioList.vue'
                ),
            title: "奖金分配比例",
        },


        {
            path: "season",
            name: "SeasonList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    // "../views/_2_core/seasonList/seasonList.vue"
                    '../views/_2_core/seasonList/seasonList.vue'
                ),
            title: "赛季配置",
        },

        {
            path: "season/bonusRules/:id",
            name: "BonusRules",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/seasonList/bonusRules.vue"
                ),
            title: "奖金规则",
            awayMenu: true
        },


        {
            path: "order",
            name: "OrderList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/orderList/orderList.vue"
                ),
            title: "债券订单",
        },
        {
            path: "order/add",
            name: "OrderAdd",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/orderEdit/orderAdd.vue"
                ),
            title: "新建订单",
            awayMenu: true
        },
        {
            path: "order/edit",
            name: "OrderEdit",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    // "../views/_2_core/orderEdit/orderAdd.vue"
                    "../views/_2_core/orderEdit/orderEdit.vue"
                ),
            title: "编辑订单",
            awayMenu: true
        },
        {
            path: "order/detail/:id",
            name: "OrderDetail",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/orderDetail/orderDetail.vue"
                ),
            title: "订单详情",
            awayMenu: true
        },




        {
            path: "eoi",
            name: "eioList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/eioList/eioList.vue"
                ),
            title: "EOI",
        },
        {
            path: "eoi/add",
            name: "EOIAdd",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/orderEdit/orderAdd.vue"
                ),
            title: "新建EOI",
            awayMenu: true
        },
        {
            path: "eoi/edit",
            name: "EOIEdit",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    // "../views/_2_core/orderEdit/orderAdd.vue"
                    "../views/_2_core/orderEdit/orderEdit.vue"

                ),
            title: "编辑EOI",
            awayMenu: true
        },
        {
            path: "eoi/detail/:id",
            name: "EOIDetail",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/orderDetail/orderDetail.vue"
                ),
            title: "EOI订单详情",
            awayMenu: true
        },


        {
            path: "queueEoi",
            name: "queueEoiList",
            component: () =>
                import(
                    // "../views/_2_core/eioList/queueEoiList.vue"
                    "../views/_2_core/queueEoiList/queueEoiList.vue"

                ),
            title: "进入排队EOI",
        },
        {
            path: "newEoi",
            name: "newEoiList",
            component: () =>
                import(
                    // "../views/_2_core/eioList/newEoiList.vue"
                    "../views/_2_core/newEoiList/newEoiList.vue"

                ),
            title: "EOI列表",
        },
        {
            path: "integralLogsList",
            name: "integralLogsList",
            component: () =>
                import(
                    "../views/_2_core/integralLogs/integralLogsList.vue"
                ),
            title: "订单积分日志",
        },





    ],
}