// 消息通知

export default {
    path: "/messages",
    name: "Messages",
    component: () =>
        import(
            /* webpackChunkName: "messages" */
            "../views/_9_messages/messages.vue"
        ),
    title: "消息通知",
    icon: "message",
    
    children: [
        {
            path: "downloadlist",
            name: "DownloadList",
            component: () =>
            import(
                /* webpackChunkName: "messages" */
                "../views/_9_messages/downloadList/downloadList.vue"
            ),
            title: "下载通知",
            // awayMenu: true, // 不在左侧导航
        },
        {
            path: "checkPayList",
            name: "CheckPayList",
            component: () =>
                import(
                    "../views/_9_messages/messageList/checkPayMoneyList.vue"
                ),
            title: "待确认回款",
        },
        {
            path: "messagelist",
            name: "MessageList",
            component: () =>
                import(
                    "../views/_9_messages/messageList/messageList.vue"
                ),
            title: "消息通知",
        },
        {
            path: "branchOfficeList",
            name: "BranchOfficeList",
            component: () =>
                import(
                    "../views/_9_messages/branchOfficeList/branchOfficeList.vue"
                ),
            title: "待分公司审核EOI",
        },
        {
            path: "headOfficeList",
            name: "HeadOfficeList",
            component: () =>
                import(
                    "../views/_9_messages/headOfficeList/headOfficeList.vue"
                ),
            title: "待总公司审核",
        }
    ],
}