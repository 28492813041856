import layout  from "../views/_0_main/layout/layout.vue"; // 布局框架
import home    from "../views/_0_main/home/home.vue";     // 首页组件
import basic   from "./module_1_basic";                   // 基础设置模块的路由
import core    from "./module_2_core";                    // 核心模块模块的路由
import message from "./module_9_messages";                // 消息通知模块的路由
import commission from "./module_3_commission";                // 佣金模块的路由
import statistics from "./module_9_statistics";                // 统计模块的路由



const options = [
	{
		path     : "",
		name     : "Main",
		title    : "首页",
		component: layout,
		redirect : "/home",
		children : [
			{
				path       : "/home",
				name       : "Home",
				component  : home,
				title      : "主页",
				descreption: "返回首页",
				icon       : "home",
			}

			, basic      // 基础设置
			, core       // 订单管理
			, message    // 消息通知
            , commission // 佣金,
			,statistics //统计

		]
	}
];



/**
 * routeList，用于在 router 中生成 routes
 */
function extractRouteOption(item) {
	// const { path, name, component, title } = item;
	if (item.children && item.children.length) {
		item.children = item.children.map(extractRouteOption);
	}
	item.meta = {title: item.title}
	return item;
}

const routeList = options.map(extractRouteOption);



/**
 * menuList，用于再 App 中导入，并渲染左侧导航目录
 */
function extractMenuProps(_item) {
	const { path, title, icon } = _item;
	const item = { path, title, iconClass: icon };

	if (_item.children && _item.children.length && !_item.awayMenu) {
		item.children = _item.children.filter(it => !it.awayMenu).map(extractMenuProps);
	}

	return item
}

const menuList = options[0].children.filter(item => !item.awayMenu).map(extractMenuProps);



export { routeList, menuList };
export default options;