// import 'element-ui/lib/theme-chalk/index.css';
import './theme/element-variables.scss';
// import "./theme/private.scss";
import Element from "element-ui";

import Vue from "vue";


// Element.Input.props.clearable.default = true;  // 输入框默认可清除
{
    /**
     * 输入框最大字符长度默认为20或50(textarea)
     * 输入框占位符默认未 "请输入"
     */
    let render = Element.Input.render;
    Element.Input.render = function() {
        if (!this.$attrs.maxlength) {
            this.$attrs.maxlength = this.type == "textarea" ? 50 : 20;
        } 
        if (!this.$attrs.placeholder) {
            this.$attrs.placeholder = "请输入";
        } 
        return render.apply(this, arguments);
    }
}

// 选择框默认可清除
// Element.Select.props.clearable = { type: Boolean, default: true }

// 点击幕布不关闭浮层
Element.Dialog.props.closeOnClickModal.default = false;
// try {
//     Element.Drawer.props.closeOnClickModal.default = false;
// } catch (e) {
//     console.warn(e)
// }
// console.log(Element.Drawer)
Vue.use(Element, {size: "small"});
// Vue.prototype.showLoading = Element.Loading.service;