import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router"
Vue.use(Vuex)
import { $axios } from "../request";

const store = new Vuex.Store({

	state: {
		token: "",
		centerWbUrl: "",
		// breadList: [],       // 当前面包屑数据
		userInfo : {},       // 登录用户信息
		listPageState: null, // 列表页记录状态, 便于从详情页返回时恢复
        sysIconMap: '',//系统图标
	},

	mutations: {

		// 设置token
		setToken: function (state, val) {
			state.token = val;
			localStorage.setItem("token", val);
		},

		// 设置token
		setUrl: function (state, val) {
			state.centerWbUrl = val;
			localStorage.setItem("centerWbUrl", val);
		},
        setSysIconMap: function (state, val) {
            state.centerWbUrl = val;
            localStorage.setItem("sysIconMap", val);
        },


		// 跟新面包屑
		// setBreadList: function (state, list) {
		// 	if (!(list instanceof Array)) {
		// 		return console.warn("store 设置面包屑失败，传入的参数不是数组", list);
		// 	}
		// 	state.breadList = list;
		// },

		// 更新登录用户信息
		setUserInfo(state, info) {
			if (info.constructor !== Object) {
				return console.warn("store 设置用户信息失败，传入的参数不是对象", info)
			}

			state.userInfo = Object.assign(state.userInfo, info);
			localStorage.setItem("zq_userInfo", JSON.stringify(info));
		},

		// 清空用户信息，在推出登录时使用
		clearUserInfo(state) {
			const target = state.centerWbUrl || "";
			state.token = "";
			state.centerWbUrl = "";
			state.userInfo = {};
			// localStorage.removeItem("token");
			// localStorage.removeItem("centerWbUrl");
			// localStorage.removeItem("zq_userInfo");
			// localStorage.removeItem("follow");
			localStorage.clear();
			if (router.app.$route.fullPath != "/home?type=1")
				router.replace("/home?type=1");
			console.log("用户信息清空了", target);
			setTimeout(() => {
				location.href = target;
			}, 1e3);
		},

		// 记录列表页的状态
		saveListPageState(state, data) {
			if (data == undefined) {
				state.listPageState = null;
				console.log("已清空列表页状态")
			} else {
				state.listPageState = data;
				console.log("保存列表页状态", data);
			}
		}
	},

	actions: {
	},

	modules: {
	}
})


// this.$hasAuthFor 函数用于检测用户权限列表中有无某个api存在
const hasAuthFor = function (apiPath, defaul = false) {
	if (defaul) return defaul;
	const { pathList } = store.state.userInfo;

	if (pathList.constructor !== Array) return false;
    // else return pathList.some(item => item.path.endsWith(apiPath))

    else return pathList.some(item =>
    (
        // item.api.endsWith(apiPath)
        // ||
        item.path.endsWith(apiPath)
    )
    )

};

Vue.prototype.$hasAuthFor = hasAuthFor;

async function getPathList(follow) {
    // alert("打开调试器")
	try {
		
		const res = await $axios({
			url: "/api/index/initLoginEmp",
			method: "post",
		});
        // console.log(res);
        // alert(JSON.stringify(res))
        // console.log(JSON.stringify(res));
        // alert(111)




		if (res.code === 2000) {
            console.log(res.data);
            console.log('follow', follow);

			delete follow.token;
            localStorage.setItem("follow", JSON.stringify(follow));
            // localStorage.setItem("sysIconMap", JSON.stringify(res.data.iconMap));
            store.commit("setSysIconMap", JSON.stringify(res.data.iconMap));


			const {userLoginDTO, centerWbUrl} = res.data;
			store.commit("setUrl", centerWbUrl);

			const list = res.data.pathList;
			list.forEach(item => {
				if (item.icon) item.icon_path = item.icon;
				item.iconClass = item.webPath?.split("/")?.[1];
				item.name = item.title = item.menuName;
				item.api = item.path;
				item.path = item.webPath;
			});
			store.commit("setUserInfo", {
				...userLoginDTO,
				pathList: list
			});
			// debugger;
            location.reload();

		}

		else if (res.code === 3004) {
			top.location.href = res.data.centerWbUrl;
		}

		else if (res.code !== 1003)
			throw res

	} catch (reason) {
		console.warn("获取用户资源失败", reason);
	}
}

export const initial = new Promise((resolve, reject) => {
	try {
		let follow = localStorage.getItem("follow");
		if (follow) follow = JSON.parse(follow);
		if (follow?.token) {
            console.log(1);
			const { token } = follow;
			store.commit("setToken", token);
			getPathList(follow)

        } else {
			// 读取缓存中的用户数据，判断是否登录，并恢复数据到 store
			const token = localStorage.getItem("token");
			token && store.commit("setToken", token);
			const centerWbUrl = localStorage.getItem("centerWbUrl");
			centerWbUrl && store.commit("setUrl", centerWbUrl);
			const userInfo = JSON.parse(localStorage.getItem("zq_userInfo"));
			userInfo && store.commit("setUserInfo", userInfo)
			resolve();
		}

	} catch (error) {
		console.warn("store 初始化出错", error)
		resolve();
	}
})

export default store;