import { menuList } from "../../../router/menu";

// 一张默认头像的 base64 格式
const defaultAvatar = require("./images/avatar.png");

export default {
	name: "Layout",

	data: () => ({
		// menu: [],
		auth: {
            checkOutCommission: false,
            clearNoticeNum: false,
			checkPayNum: false
		},
		menu: [menuList[0]],
		isCollapse: true,
		defaultAcitve: "", // 当前激活的导航
		expandMenu: null, // 当前展开的二级菜单
		defaultAvatar,    // 默认头像
		breadList: [],
		message: {
			expiringOrderCount: 0,
            chanCommCount: 0,
            imClearNoticeNum: 0,
            clearNoticeNum: 0,
			payMoneyCount:0
        },
        sysIconMap: '',
        sysName: '',
        mainPath: '', // require('./images/logo.png')
	}),

	computed: {
		// ...mapState(["breadList"]), // 面包屑
		asideWidth() {
			return (this.isCollapse ? 64 : 200) + "px"
		}
	},

	created() {
		this.generateMenu();
		this.auth.checkOutCommission  = this.$hasAuthFor("api/orderChanComm/saveCheckChanComm");
        this.auth.clearNoticeNum = this.$hasAuthFor("/messages/messageList");
		this.auth.checkPayNum = this.$hasAuthFor("/messages/checkPayList");

		// 设置当前展开的二级菜单
		if (location.pathname) {
			this.expandMenu = "/" + location.pathname.split("/")[1]; // 第一个为空字符串，因为开头为/
			this.fetchMessages()
		}

        this.sysIconMap = JSON.parse(localStorage.getItem("sysIconMap"))
        if (this.sysIconMap) {
            if (this.sysIconMap.mainPath) {
                // this.mainPath = this.$baseURL + '/' + this.sysIconMap.mainPath
                this.mainPath = this.sysIconMap.realMainPath;
            } else {
                this.mainPath = require('./images/logo.png')
            }
            if (this.sysIconMap.name) {
                this.sysName = this.sysIconMap.name
            } else {
                this.sysName = '债券系统'
            }

        }

        console.log(this.sysIconMap.realSysPath + this.sysIconMap.sysPath);

        if (this.sysIconMap.sysPath) {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            // link.href = this.$baseURL + '/' + this.sysIconMap.sysPath;
            link.href = this.sysIconMap.realSysPath;
            document.getElementsByTagName('head')[0].appendChild(link);
        } else {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            // link.href = require('./images/favicon.ico');
            link.href = require('./images/logo.png');
            document.getElementsByTagName('head')[0].appendChild(link);

        }

	},

	methods: {
		// 根据用户的权限列表生成目录
		generateMenu() {
			if (!this.userInfo.pathList) {
				this.generatecount = this.generatecount || 0;
				if (this.generatecount < 10) {
					this.generatecount++;
					setTimeout(() => {
						this.generateMenu();
					}, 500)
					return;
				} else {
					return this.generatecount=0, console.warn("没有获取到用户资源列表");
				}
			}

			this.generatecount=0;
			const { pathList } = this.userInfo;
			/**
			* 用户url权限数据，根据升序排序
			* 再把 子数据 父级的 children 中
			*/
			const navUrlList = [...(pathList||[])];
			navUrlList.sort((prev, next) => {
				if (prev.type != next.type) {
					return prev.type - next.type;
				} else {
					return prev.order - next.order;
				}
			});
			
			let i = navUrlList.length - 1;
			while (i >= 0 && navUrlList[i].type > 0) {
				const curItem = navUrlList[i];
				for (let j = i - 1; j >= 0; j--) {
					if (curItem.parentId == navUrlList[j].id) {
						if (!(navUrlList[j].children instanceof Array)) {
							navUrlList[j].children = [];
						}
						// 此处 push 会使排序颠倒
						navUrlList[j].children.unshift(curItem);
						navUrlList.length = i;
						break;
					}
				}
				i--;
			}
			this.menu.splice(1, this.menu.length, ...navUrlList.filter(item => 
				!(item.path === "/home" && item.children))); // 排除掉主页那个模块，因为那个模块的结构和其他模块的结构不一致，我们用固定this.menu[0]的代替
            // console.log(this.menu);
			this.defaultAcitve = this.extractTop2Path();
		},

		// 切换导航折叠状态
		toggleCollapse(value) {
			if (typeof value === "boolean") this.isCollapse = value;
			else this.isCollapse = !this.isCollapse
		},

		// 二级菜单展开的事件处理函数
		handleSubMenuOpen(path) {
			if (this.expandMenu && this.expandMenu !== path) {
				this.$refs.menu.close(this.expandMenu);
			}
			this.expandMenu = path;
		},

		// 点击头像
		handleAvatarClick() {
			// if (this.$route.path !== "/my/updatePwd")
			// 	this.$router.push("/my/updatePwd");
		},

		// 点击退出
		handleSignOut() {

			this.$confirm('确定要退出登录吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$store.commit("clearUserInfo");
				// // 清空用户信息
				// localStorage.removeItem("token");
				// localStorage.removeItem("userInfo");
				// location.reload();
			});

		},

		// 提取当前匹配的路由的前两层："/basic/banner/edit/2" -> "/basic/banner"
		// 用于设置目录的当前高亮项
		extractTop2Path(path) {
			path = path || this.$route.fullPath;
			return path.match(/^(\/\w*){1,2}/)[0]
		},

		// 获取用户通知列表
		async fetchMessages () {
			try {
				const res = await this.$axios({
					url: "/api/index/queryIndexMessage",
					method: "post",
				});

				if (res.code === 2000) {
					this.message.expiringOrderCount = res.data.expiringOrderCount
					this.message.chanCommCount = res.data.chanCommCount
                    this.message.imClearNoticeNum = res.data.imClearNoticeNum
                    this.message.clearNoticeNum = res.data.clearNoticeNum
					this.message.payMoneyCount = res.data.payMoneyCount
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获用户通知失败", reason);
			}
		},

		async handleExpiringButton () {
			if (this.$route.path === "/core/order") {
				await this.$router.push("/home")
			}

			let today = new Date(), M = today.getMonth();
			const todayStr = this.getDateStr(today);
			today.setMonth(M+3);

			this.$router.push({
				name: "OrderList",
				params: {
					searchTimeType: 2,
					searchStartTime: todayStr,
					searchEndTime: this.getDateStr(today),
					searchState  : [1]
				}
			})
		},

		async handleOutChannelCheckingButton () {
            if (this.$route.path === "/commission/outChannelCommission") {
				await this.$router.push("/home")
			}

			this.$router.push({
				name: "OutChannelCommission",
				params: {
					searchCheckState: 0
				}
			})
		},
        // 清退通知 即将清退通知
        async handleToMessage(noticeType) {
            this.$router.push({
                name: "MessageList",
                params: {
                    noticeType: noticeType
                }
            })
        },
		// 待确认缴费到账
        async handleToCheckPay() {
			
            this.$router.push({
                name: "CheckPayList",
				params: {
					searchStartTime:'2024-01-01'
                }
                
            })
        },


		getDateStr (date) {
			if (date instanceof Date) {
				let Y = date.getFullYear();
				let M = date.getMonth() + 1;
				let D = date.getDate();
				M = M < 10 ? `0${M}` : M;
				D = D < 10 ? `0${D}` : D;
	
				return `${Y}-${M}-${D}`;
			}
			else return ""
		}
	},

	watch: {
		"$route": {
			immediate: true,
			handler: function (val) {
				this.defaultAcitve = this.extractTop2Path(val.fullPath);

				// 更新面包屑
				const { pathList } = this.userInfo;

                // console.log(pathList);

				if (val.fullPath == "/home")
					return this.breadList = [{
						name: "首页",
						to: ""
					}];

				let breadList = [],
					currPath  = "",
					restPath  = val.fullPath.split("/").filter(
						dName=>(Boolean(dName)&&!(/^\d+(\?.*)?$/.test(dName)))),
					flag      = true;

                // console.log(restPath);
                // console.log(pathList);
				while (restPath.length) {
					const dName = restPath.shift()
					currPath += ("/" + dName);
					if (dName == "add") {
						breadList.push({
							name: "新增",
							to: ""
						})
					}
					else if (dName == "edit") {
						breadList.push({
							name: "编辑",
							to: ""
						})
					}
					else {
                        // console.log(currPath);


						const item = pathList?.find(it => it.webPath == currPath);
						if (item) breadList.push({
							name: item.menuName,
							// to: flag || (currPath == val.fullPath) ? "" : currPath
							to: flag || (!restPath.length) ? "" : currPath
						})
						else {
							breadList.push({
								name: val.meta.title,
								// to: flag || (currPath == val.fullPath) ? "" : currPath
								to: flag || (!restPath.length) ? "" : currPath
							});
							break;
						}
						flag = false;
					}
				}
				this.breadList = breadList;
                // console.log(this.breadList);
			}
		}
	}
};
