/**
 * 这个文件配置要全局引入的组件的配置
 * 将在入口文件 main.js 中被使用
 */

import Vue from "vue";

// 列表工具栏 布局 样式
Vue.component("list-tool-bar", () =>
    import("./ListToolBar.vue"));

// 列表搜索栏 布局 样式
Vue.component("list-search-bar", () => 
    import("./ListSearchBar.vue"));

// 列表底部工具栏 布局 样式
Vue.component("list-foot-bar", () => 
    import("./ListFootBar.vue"));

// 表单分组的组标题 样式
Vue.component("form-group-title", () => 
    import("./FormGroupTitle.vue"));

// 缩略图列表：一组缩略图
Vue.component("thumbnail-list", () => 
    import("./ThumbnailList.vue"));

// 缩略图列表：一组缩略图
Vue.component("thumbnail-list1", () => 
    import("./ThumbnailList1.vue"));

// 操作菜单列表：一组操作菜单
Vue.component("action-list", () => 
    import("./ActionList.vue"));